import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { xAppSource } from 'Constants/constant'
import { apiEndpoints } from 'Utils/ApiEndpoints'
import Toast from 'Utils/Toast'

const baseUrl = process.env.REACT_APP_BASE_URL
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth?.authData?.token
      const csrfTokenn = getState().auth?.authData?.csrfTokenn
      if (csrfTokenn) {
        headers.set('X-CSRF-TOKEN', csrfTokenn)
        headers.set('x-app-source', xAppSource)
      }
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }

      if (!(args.body instanceof FormData)) {
        headers.set('Content-Type', 'application/json')
      }
      return headers
    }
    // credentials: "include",
  })(args, api, extraOptions)

  if (result?.error && result?.error.status === 401) {
    window.location.href = '/'
  }
  if (result?.error && result?.error.status === 400) {
    Toast('e', result?.error?.data?.message)
    console.log('result', result?.error?.data?.message)
  } else return result
}

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['User'],
  endpoints: builder => ({
    getDataProfile: builder.query({
      query: () => ({
        url: `${apiEndpoints?.getProfileData}`,
        method: 'GET'
      }),
      providesTags: ['User']
    }),
    updatePassword: builder.mutation({
      query: data => ({
        url: apiEndpoints?.updatePassword,
        method: 'POST',
        body: data
      }),
      providesTags: ['User']
    }),
    updateAdminData: builder.mutation({
      query: data => ({
        url: apiEndpoints?.updateAdmin,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['User']
    }),
    getDashboard: builder.query({
      query: () => ({
        url: `${apiEndpoints?.getDashboardUrl}`,
        method: 'POST'
      })
    }),
    getDashboardGraph: builder.query({
      query: ({ filter = '' }) => ({
        url: `${apiEndpoints?.getDashboardGraphUrl}?filterType=${filter}`,
        method: 'GET'
      })
    })
  })
})

export const {
  useUpdatePasswordMutation,
  useUpdateAdminDataMutation,
  useGetDataProfileQuery,
  useGetDashboardQuery,
  useGetDashboardGraphQuery
} = profileApi
