import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {xAppSource } from 'Constants/constant';
import { apiEndpoints } from 'Utils/ApiEndpoints';

const baseUrl = process.env.REACT_APP_BASE_URL
const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth?.authData?.token;
            const csrfTokenn = getState().auth?.authData?.csrfTokenn;
            if (csrfTokenn) {
                headers.set("X-CSRF-TOKEN", csrfTokenn);
                headers.set("x-app-source", xAppSource);
            }
            //app_call --locally
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
        // credentials: "include",
    })(args, api, extraOptions);

    if (result?.error && result?.error.status === 401) {
        window.location.href = '/'
    }
    return result;
};

export const contactUsApi = createApi({
    reducerPath: 'contactUsApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['contactUs'],
    endpoints: (builder) => ({
        getContactUsList: builder.query({
            query: ({ page, limit,searchData}) => ({
                url: `${apiEndpoints?.contactUsList}?page=${page}&limit=${limit}&search_key=${searchData ?? ''}`,
                method: 'GET',
            }),
            providesTags: ['contactUs'],
        }),
        replyContactUs: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.contactUsReply,
                method: "POST",
                body: data
            }),
            invalidatesTags: ['contactUs'],
        }),
       
    })
});

export const {
    useGetContactUsListQuery, useReplyContactUsMutation
} = contactUsApi;
