import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { xAppSource } from 'Constants/constant';
import { apiEndpoints } from 'Utils/ApiEndpoints';

const baseUrl = process.env.REACT_APP_BASE_URL
const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth?.authData?.token;
            const csrfTokenn = getState().auth?.authData?.csrfTokenn;
            if (csrfTokenn) {
                headers.set("X-CSRF-TOKEN", csrfTokenn);
                headers.set("x-app-source", xAppSource);
            }
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
        // credentials: "include",
    })(args, api, extraOptions);

    if (result?.error && result?.error.status === 401) {
        window.location.href = '/'
    }
    return result;
};

export const findApi = createApi({
    reducerPath: 'findApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Find'],
    endpoints: (builder) => ({
        getFindData: builder.query({
            query: ({ page, limit, searchData, status }) => ({
                url: `${apiEndpoints?.findGetUrl}?page=${page}&limit=${limit}&status=${status}&search_key=${searchData ?? ''}`,
                method: 'GET',
            }),
            providesTags: ['Find'],
        }),
        getFindDataDetails: builder.query({
            query: (findId) => ({
                url: `${apiEndpoints?.findDetailsUrl}?find_id=${findId}`,
                method: 'GET',
            }),
            providesTags: ['Find'],
        }),
        updateFindStatus: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.findUpdateStatusUrl,
                method: "POST",
                body: data
            }),
            invalidatesTags: ['Find'],
        }),
       
    })
});

export const {
    useGetFindDataQuery, useGetFindDataDetailsQuery, useUpdateFindStatusMutation
} = findApi;
